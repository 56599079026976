<template>
  <v-col>
    <p class="title">Référence : {{ items[0].name }}</p>
    <p class="sutitle">{{ title }}</p>
    <v-simple-table dense>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">Entretien</th>
          <th class="text-left">Score</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item,i) in items" :key="i">
          <td>{{ item.name | truncate(14, '...') }}</td>
          <td><v-progress-linear v-model="item.score" :color="color" height="15"
            :buffer-value="max">
            <template v-slot:default="{ value }">
              <span class="white--text"><strong>{{ value | round }}{{ pct ? '%':''}}</strong></span>
            </template>
          </v-progress-linear></td>
        </tr>
      </tbody>
    </template>
    </v-simple-table>
 </v-col>
</template>

<script>

export default {
  name: 'Rank',
  props: {
    items: Array,
    title: String,
    color: String,
    pct: Boolean,
  },
};
</script>
