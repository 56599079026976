<template>
  <v-container style="margin-left: 60px;">
    <v-row class="flex-row align-self-stretch">
      <v-col cols="2">
        <v-row>
          <v-col>
            <p class="title">Comparaison d'entretiens
              <v-chip bordered color="error">
                <v-icon>mdi-beaker</v-icon> Expérimental
              </v-chip>
            </p>
            <v-progress-linear v-show="loading" indeterminate></v-progress-linear>
        </v-col>
      </v-row>
      <v-sheet>
        <p class="subtitle">Entretiens</p>
        <v-text-field v-model="search" label="Filtrer par entretien ou patient..."
        light flat hide-details clearable clear-icon="mdi-filter-remove">
      </v-text-field>
    </v-sheet>

    <v-treeview hoverable open-on-click v-model="selection"
    :items="items" :search="search" dense  selection-type="leaf"
    selectable return-object>
    <template v-slot:prepend="{ item }">
      <v-icon>{{ item.children ? 'mdi-account-circle' : 'mdi-message-text' }}</v-icon>
    </template>
  </v-treeview>
  <v-select
    v-model="reference"
    :items="referenceSelection"
    item-text="name"
    item-value="file"
    single-line
    label="Sélectionner la référence"
  ></v-select>
  <v-btn outlined block @click="compare()"
    color="indigo" class="mt-5">Comparer</v-btn>
  </v-col>
  <v-col cols="10" class="align-center">
    <v-row>
      <v-col cols="4">
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Composante</th>
                <th class="text-left">(Dés)activé</th>
                <th class="text-left">Coefficient</th>
                <th class="text-left"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item,i) in mask" :key="i">
                <td>{{ item.name }}</td>
                <td>
                  <v-checkbox dense v-model="item.active"></v-checkbox>
                </td>
                <td><v-slider
                  v-model="item.factor"
                  step="0.01"
                  max="1"
                  min="0"
                ></v-slider></td>
                <td>
                  <v-text-field
                  name="number"
                  dense
                  type="number"
                  step="0.01"
                  max="1.0"
                  min="0.0"
                  :disabled="!item.active"
                  :rules="[(v) => (v >= 0 && v <= 1)]"
                  v-model="item.factor"/>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-col v-if="!loading">
        <Rank :items="rank" color="red" title="Similarité cosinus" pct/>
      </v-col>
      <v-col v-if="!loading">
        <Rank :items="bhattacharya" color="orange" title="Distance Bhattacharya"/>
      </v-col>
      <v-col v-if="!loading">
        <Rank :items="kl" color="blue" title="Divergence KL"/>
      </v-col>
      <v-col v-show="selection.length === 0">
        <div
        class="mx-auto justify-center d-block">
        <v-img class="mx-auto mb-5" src="../../assets/transverse.svg" max-width="500"></v-img>
        <p class="text-center display-1 font-weight-thin grey--text text--lighten-1">
          Pas d'entretien sélectionné
        </p>
      </div>
    </v-col>

  </v-row>
  <v-row v-if="series.length" class="d-flex align-self-stretch">

    <apexchart type="heatmap" height="750" :options="chartOptions" :series="series"></apexchart>
    <apexchart type="scatter" :options="pcaOptions" :series="pca"></apexchart>
  </v-row>

</v-col>
</v-row>
</v-container>
</template>

<script>

import VueApexCharts from 'vue-apexcharts';
import MixinPatients from '@/mixins/patients';
import MixinInterviews from '@/mixins/interviews';
import Rank from './Rank.vue';

export default {
  name: 'InterviewsComparison',
  mixins: [MixinInterviews, MixinPatients],
  components: { Rank, apexchart: VueApexCharts },

  async mounted() {
    const patients = await this.getPatientList();
    let index = 1;

    patients.forEach(async (patient) => {
      index += 1;
      const treeElement = { id: index, name: patient.name, children: [] };

      // eslint-disable-next-line no-underscore-dangle
      const interviews = await this.getInterviewsList(patient._id);

      if (interviews.length) {
        interviews.forEach((interview) => {
          index += 1;
          treeElement.children.push({
            id: index,
            name: interview.name,
            file: interview.textFile,
            // eslint-disable-next-line no-underscore-dangle
            interviewId: interview._id,
            // eslint-disable-next-line no-underscore-dangle
            patientId: patient._id,
          });
        });
        this.items.push(treeElement);
      }
    });
  },

  computed: {
    referenceSelection() {
      return this.selection.map((x) => ({ name: x.name, file: x.file }));
    },
    chartOptions() {
      return {
        plotOptions: {
          heatmap: {
            colorScale: {
              min: 3,
              max: 5,
              ranges: [{
                from: 100,
                to: 100,
                color: '#EEEEEE',
                name: 'identic',
              },
              {
                from: 0,
                to: 94,
                color: '#CC3300',
                name: 'low',
              },
              {
                from: 91,
                to: 96,
                color: '#0033CC',
                name: 'medium',
              },
              {
                from: 97,
                to: 100,
                color: '#33CC00',
                name: 'high',
              }],
            },
          },
        },
        xaxis: {
          type: 'category',
          categories: this.selection.map((x) => (x.name)),
        },
        dataLabels: {
          enabled: true,
        },
        colors: ['#008FFB'],
        chart: {
          height: 700,
          type: 'heatmap',
        },
        title: {
          text: 'Comparaison des entretiens',
        },
      };
    },
  },

  methods: {
    async compare() {
      this.loading = true;
      const interviewsList = this.selection.map(
        (x) => ({ patientId: x.patientId, interviewId: x.interviewId }),
      );

      const mask = this.mask.filter((x) => (x.active)).map((x) => (x.name));

      const factors = this.mask.filter((x) => (x.active)).map((x) => (x.factor));

      const result = await this.getInterviewsHeatmap(
        interviewsList,
        mask,
        factors,
        this.reference,
        ['all'],
      );

      this.series = result.heatmap.map((item, i) => {
        const copy = item;
        copy.name = this.selection[i].name;
        return copy;
      });

      this.rank = result.rank.map((item) => {
        const copy = item;
        copy.name = this.selection.find((x) => (x.file === copy.file)).name;
        return copy;
      }).sort((a, b) => (a.score < b.score));

      this.bhattacharya = result.bhattacharya.map((item) => {
        const copy = item;
        copy.name = this.selection.find((x) => (x.file === copy.file)).name;
        return copy;
      }).sort((a, b) => (a.score < b.score));

      this.kl = result.kl.map((item) => {
        const copy = item;
        copy.name = this.selection.find((x) => (x.file === copy.file)).name;
        return copy;
      }).sort((a, b) => (a.score > b.score));

      this.pca = result.pca;

      this.loading = false;
    },
  },

  data: () => ({
    selection: [],
    normalized: false,
    patients: null,
    search: '',
    rank: null,
    kl: null,
    bhattacharya: null,
    interviews: null,
    items: [],
    panel: false,
    loading: false,
    series: [],
    pca: [],
    reference: '',
    mask: [
      { active: false, factor: 1.0, name: 'turns' },
      { active: false, factor: 1.0, name: 'words' },
      { active: false, factor: 1.0, name: 'averageLength' },
      { active: false, factor: 1.0, name: 'typeTokensRatio' },
      { active: false, factor: 1.0, name: 'formsTokensRatio' },
      { active: true, factor: 1.0, name: 'commonTags' },
      { active: true, factor: 1.0, name: 'lexicalDensity' },
      // { active: false, factor: 1.0, name: 'stddevVH' },
      { active: false, factor: 1.0, name: 'verbalRootsRatio' },
      { active: true, factor: 1.0, name: 'arity' },
      // { active: false, factor: 1.0, name: 'dependencyLengthDistribtion' },
      { active: true, factor: 1.0, name: 'clausesLength' },
      { active: false, factor: 1.0, name: 'flow' },
      { active: false, factor: 1.0, name: 'objectOrder' },
      { active: false, factor: 1.0, name: 'verbalOrder' },
      { active: false, factor: 1.0, name: 'subordinatesRatio' },
      { active: true, factor: 1.0, name: 'subordinatesOrder-pre' },
      { active: false, factor: 1.0, name: 'subordinatesOrder-post' },
    ],

    dialog: false,
    pcaOptions: {
      chart: {
        height: 700,
        type: 'scatter',
        zoom: { enabled: true, type: 'xy' },
      },
      xaxis: {
        forceNiceScale: true,
        tickAmount: 10,
        labels: {
          formatter: (val) => (parseFloat(val).toFixed(1)),
        },
      },
      yaxis: {
        forceNiceScale: true,
        tickAmount: 10,
        labels: {
          formatter: (val) => (parseFloat(val).toFixed(1)),
        },
      },
    },
  }),
};
</script>
